@media screen and (max-width: 767px) {
  .img-containerInsu {
    max-height: 300px;
  }

  .agregarCont {
    position: fixed;
    background-color: white;
    border-top: black 3px solid;
    bottom: 0px;
    left: 0%;
    padding: 10px;
    z-index: 999;
    width: 100%;
    margin: 0% !important;
  }
  .img-containerInsu {
    min-height: 250px;
  }
}

@media screen and (min-width: 768px) {
  .agregarCont {
    border: black solid 3px;
    border-radius: 10px;
    padding: 8px;
  }
}

.asacardRubro {
  border: solid black 3px;
  transition: transform 0.3s ease;
}



.img-containerInsu {
  border: black solid 3px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.preciosInsu {
  border: black solid 3px;
  border-radius: 10px;
  padding: 8px;
  margin-bottom: 10px;
  text-align: center;
  cursor: pointer;
}

.preciosInsu.desactivado {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.preciosInsu.desactivado:hover {
  opacity: 1;
}

.preciosInsu.activo {
  opacity: 1;
  pointer-events: auto;
}

.cuerpoInsu {
  text-align: left;
}

.tituloInsu {
  font-weight: bold;
}

.textoInsu {
  font-size: large;
}

.totalInsu {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
  justify-content: center;
  align-items: center;
}

.sumayresta {
  border: black solid 3px;
  border-radius: 6px;
  height: 42px;
}

.inputSyR {
  padding-right: 0;
  padding-left: 0;

  height: 37px;
  font-size: 1.4em;
}

.uniInput {
  font-size: 1.2em;
  margin-top: 6px;
  margin-right: 10px;
}

.btnResta,
.btnSuma {
  padding: 2px;
}

.btnAgregar {
  border: var(--third-color) solid 3px;
  background-color: var(--secondary-color);
  transition: transform 0.1s ease;
}

.btnAgregar:active {
  transform: scale(0.9);
}

.checkInsumo {
  top: 20px;
  right: 20px;
  background-color: white;
  border-radius: 50%;
}
