.cardRubro {
  border: solid black 3px !important;
  transition: transform 0.3s ease;
}

.cardRubro:hover {
  transform: scale(
    1.05
  ); /* Escala levemente la tarjeta en un 5% al pasar el mouse por encima */
}

.img-container {
  width: 100%;
  height: 140px !important;
  object-fit: cover;
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.link {
  text-decoration: none !important; /* Anula el subrayado */
}
