.bannerCont {
  width: 100%;
  height: 190px;
margin: auto;
}

.bannerImg {
  width: 100%;
  height: 130px;
  background: url(/src/img/banner.webp) no-repeat center center;
  background-size: cover;
  border-radius: 10px;

}


.bannerInfo{
  margin-left: 20px;
  padding-top: 80px;
  display: flex;
  align-items: end;
}
