.resumenDatos {
  background-color: #f8f9fa;
}

.checkEntrega {
  font-size: 1.3em;
}

.direDatos {
  font-size: 0.8em;
}

.otrDatos {
  font-size: 0.8em;
}

.inputDatos {
  border: solid black 3px;
}

.inputDatos:focus {
  border: solid black 3px;
}



.my-swal-efect-button {
  background-color: #99bc60 !important;
  color: white !important;
  border: var(--third-color) solid 3px;
  font-family: var(--primary-font);

  border-radius: 4px;
  padding: 5px 20px;
  margin: 5px;
  font-size: 1.5rem;

  width: 80%;
}

.my-swal-efect-button:hover {
  border: #99bc60 3px solid !important;
  cursor: pointer;
}


.my-swal-mp-button {
    background-color: #219fe0 !important;
    color: white !important;
    border: var(--third-color) solid 3px;
    font-family: var(--primary-font);
  
    border-radius: 4px;
    padding: 5px 20px;
    margin: 5px;
    font-size: 1.5rem;
    
    width: 80%;
}
  
  .my-swal-mp-button:hover {
    border: #219fe0  3px solid !important;
    cursor: pointer;
  }

  .ellipsisHover:hover{
    transform: scale(1.1);
  }
