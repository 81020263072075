@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:wght@400;700&family=Lilita+One&display=swap');

:root {
    --primary-color: #fa1415;
    --secondary-color: #fbba31; 
    --third-color: #230504 ;
    --fourth-color: #fcfbfa ; 

    --primary-font: 'Josefin Sans', sans-serif;

    --primary1-color: #fa1415;
    --secondary1-color: #fbba31; 

    --primary2-color: #e85958;
    --secondary2-color: #f7be5c; 
}
