.cuerpo {
  border-bottom: var(--primary-color) solid 5px;
  display: flex; /* Añadido para permitir que los hijos se expandan */
  padding: 7px 0;

  /*position: fixed;
  top: 0%;
  background-color: white;
  z-index: 10;
  width: 100%;*/
}

/* -- */

.navbarLeft {
  flex-grow: 1; /* Añadido para que tome el ancho del padre */
  display: flex; /* Añadido para permitir que los hijos se expandan */
  align-items: center; /* Añadido para centrar el logo verticalmente */
  padding-left: 5vw;
}

.logo {
  width: 140px; /* Ajustado para que tome el 100% del alto del contenedor padre */
  height: auto;
}

.titulo {
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  font-weight: bold;
  color: var(--third-color);
}

/* -- */

.navbarCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* -- */

.navbarRight {
  color: var(--third-color);
  display: flex;
  align-items: center;
  padding-right: 5vw;
}

.icon {
  padding: 5px;
  color: var(--third-color);
  border: 0;
  background-color: transparent;
}

.indicador {
  position: absolute;
  top: 25px;
  background-color: var(--primary-color) !important;
}

/* -- */

.offcanvasCarrito {
  border-left: var(--primary-color) solid 5px !important;
}

.disabled {
  pointer-events: none; /* Deshabilita eventos de puntero, como clics y hover */
  opacity: 0.5; /* Reduce la opacidad para indicar visualmente que está deshabilitado */
}


/* -- */


.navIconText{
  font-size: 1.2em;
}


