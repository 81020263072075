.embla {
  width: 100%;
  margin: auto;
  --slide-height: 19rem;
}

@media (max-width: 985px) {
  .embla{
    --slide-size: 101%;
  }
}

@media (min-width: 984px) {
  .embla{
    --slide-size: 50%;
  }
}

.embla__viewport {
  overflow: hidden;
}
.embla__container {
  backface-visibility: hidden;
  display: flex;
  touch-action: pan-y;
  margin-left: calc(var(--slide-spacing) * -1);
}
.embla__slide {
  padding-right: 10px;
  flex: 0 0 var(--slide-size);
  min-width: 0;
  padding-left: var(--slide-spacing);
}
.embla__slide__number {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 4rem;
  font-weight: 600;
  align-items: center;
  justify-content: center;
}
.embla__controls {

  display: grid;
  grid-template-columns: auto 1fr;
  justify-content: space-between;
  gap: 1.2rem;
}
.embla__buttons {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 0.6rem;
  align-items: center;
}
.embla__button {
  -webkit-tap-highlight-color: rgb(133, 8, 8);
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  border: solid black;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  padding: 0;
  margin: 0;
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 2rem;
  height: 2rem;
  z-index: 1;
  border-radius: 50%;
  color: black;
  display: flex;
  align-items: center;
  justify-content: center;
}
.embla__button:disabled {
  color: rgb(141, 141, 141);
}
.embla__button__svg {
  width: 35%;
  height: 35%;
}
.embla__dots {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: center;
}
.embla__dot {
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  touch-action: manipulation;
  display: inline-flex;
  text-decoration: none;
  cursor: pointer;
  border: solid black;
  padding: 0;
  margin: 0;
  width: 1.6rem;
  height: 1.6rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}
.embla__dot:after {
  box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
  width: 0.4rem;
  height: 0.4rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  content: '';
}
.embla__dot--selected:after {
  box-shadow: inset 0 0 0 0.2rem var(red);
  background-color: black;
}
