.colorEstado{
  height: 350px;
}

@media (max-width: 768px) {
  .containerEstado{
    width: 100%;
}}
@media (min-width: 768px) {
  .containerEstado{
    width: 75%;
  }
}