.cardPubli {
  border: solid black 3px !important;
  transition: box-shadow 0.3s ease; /* Transición suave para el efecto de hover */
  margin-bottom: 5px;

}

.cardPubli:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Sombras con valores X, Y, desenfoque y color */
}

.img-publi {
  width: 100%;
  height: 165px;
  border-radius: 3px;
  background-color: #868e96;
}
