.sinPedidos{
  width: fit-content;
}

.cursorPointer{
  cursor: pointer;
}

.cursorPointer:hover{
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
}
