@import '/src/css/assets.css';

/*Hola*/

.Index {
  font-family: var(--primary-font);
  text-align: center;
}

.App-logo {
  pointer-events: none;
}

@media (min-width: 570px) {
  .App-logo {
    height: 50vmin;
  }
}
@media (max-width: 569.98px) {
  .App-logo {
    height: 60vmin;
  }
}

.App-header {
  background-color: var(--primary2-color) ;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  }

.divArriba{
position: absolute;
top: 0;
padding: 50px;
}

.divAbajo{
position: absolute;
bottom: 0;
}

.App-link {
  border: var(--third-color) solid 3px;
  background-color: var(--secondary-color);
  font-size:x-large;
}

.App-link:hover{
  border: var(--fourth-color) solid 3px;
  background-color: var(--secondary-color);
}

.linkPuntaje{
  padding: 10px;
  font-size:medium;
  color: var(--third-color);
}

.solo{
  font-size:large;
}

.solo:hover{
  color: var(--secondary-color);
}

