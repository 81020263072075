.cardArticulo {
  border: solid black 3px;
  transition: transform 0.3s ease;
  max-width: 540px;
  text-align: left;
}

.cardArticulo:hover {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.07);
}

.parteImg {
  background-size: cover;
  background-position: center;
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.parteTexto {
  display: flex;
  flex-direction: column;
}

.card-body {
  padding-bottom: 0;
  padding-top: 0px;
  padding-left: 10px;
  padding-right: 10px;
}

.card-title,
.card-footer {
  font-weight: bold !important;
}

.card-title,
.card-text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Número de líneas que deseas mostrar */
  -webkit-box-orient: vertical;
}

.card-footer {
  font-size: 20px;
  padding-top: 0;
  padding-bottom: 0%;
  padding-left: 10px;
  padding-right: 10px;
}

.precio {
  padding-top: 6px;
  font-size: 1.1em;
}

.btnSumaResta {
  font-size: 25px;
  margin: 0% !important;
  padding: 0% !important;
}

.btns {
  text-decoration: none;
  color: black;
  border: none;
  background-color: transparent;
  transition: transform 0.1s ease;
  margin: 0% !important;
  padding: 0% !important;
}

.btns:hover {
  transform: scale(1.1);
}

.btns:active {
  transform: scale(1);
}

.cant {
  width: 40px;
  height: 30px;
  border: 0cap;
  font-size: 1em;
  font-weight: bold;
  outline: none;
  box-shadow: none;
}

.cantCarrito {
  width: 72px;
}

.link {
  text-decoration: none !important; /* Anula el subrayado */
  color: black;
}

.articuloa{
  position: relative;
}

.sinStock{
  position: absolute;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: bold;
  border: black 3px solid;
  background-color: white;
  padding: 5px;
  padding-bottom: 0px;
  border-radius: 5px;
  pointer-events: none;
}

.disabledArt{
  opacity: 0.4;
  pointer-events: none;
}