.wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh; /* Ocupa al menos la altura completa de la ventana */
}

.conAut {
  height: 100%; /* Ocupa toda la altura disponible */
  display: flex; /* Flexbox para centrar el contenido dentro del contenedor */
  align-items: center; /* Centra el contenido verticalmente */
}

.borderErika {
  border: solid var(--third-color) 3px;
}

.inputAut {
  border: solid transparent 3px;
}

.inputAut:focus {
  border: solid transparent 3px;
}

.btnIni {
  border: var(--third-color) solid 3px;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.btnIni:hover {
  border: var(--primary-color) 3px solid !important;
}

.btnRegis{
  border: var(--third-color) solid 3px;
  background-color: var(--secondary-color) !important;
  color: black !important;
}

.btnRegis:hover {
  border: var(--secondary-color) 3px solid !important;
}

.form-check-label{
  cursor: pointer;
}

.form-check-input {
  color: var(--third-color) !important;
  cursor: pointer;
}

.form-check-input:focus {
  border: solid var(--third-color);
  box-shadow: none;
}

.form-check-input:checked {
  border: black !important;
  background-color: var(--primary-color) !important;
}

.txtAlerta{
  color: var(--primary-color)
}


.swal2-modal{
  border: solid var(--third-color) 3px; 
}

.my-swal-title {
  font-size: 2rem;
  color: #333;
  font-family: var(--primary-font);
}

.my-swal-confirm-button {
  background-color: var(--secondary-color) !important;
  color: black !important;
  border: var(--third-color) solid 3px;
  font-family: var(--primary-font);

  border-radius: 4px;
  padding: 5px 20px;
  font-size: 1rem;
}

.my-swal-confirm-button:hover {
  border: var(--secondary-color) 3px solid !important;
  cursor: pointer;
}

