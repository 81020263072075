.scrollCarrito{
  overflow-y: auto;
  height: 80%;
}

.vacio {
  padding: 5px;
  border: solid var(--third-color);
  border-radius: 10px;
}

.vacioText {
  padding: 5px;
}

.precioFinalCarrito{
  box-shadow: 0px -4px 6px -3px rgba(61, 61, 61, 0.5);
z-index: 2;
}
