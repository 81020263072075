.conte {
  margin-top: 20px;
}

.mapa {
  border: black solid 3px !important;
  border-radius: 10px;
}

.txtBuscar {
  text-align: left;
}

.btnFooter {
  border: var(--third-color) solid 3px;
  background-color: white !important;
  color: var(--primary-color) !important;
}

.btnFooter:hover {
  border: var(--primary-color) 3px solid !important;
  background-color: var(--primary-color) !important;
  color: white !important;
}

.btnWapp {
  border: var(--third-color) solid 3px;
}

.iconRedes {
  padding: 5px;
}

.perse {
  color: black;
  cursor: pointer;
}

.perse:hover{
  text-decoration: none;
}
