.buscador {
  width: 100%;
  margin: 10px 0;
}

.buscar {
  border: var(--third-color) solid 3px;
  border-radius: 10px;
}

.form-control {
  border-color: transparent;
}

.form-control:focus {
  background-color: var(--fourth-color);
  border-color: transparent;
  box-shadow: none;
}

.sinColor {
  border: transparent;
  background-color: var(--fourth-color);
  border-radius: 10px !important;
}

.inputBuscar {
  padding: 0%;
}

.rbt-menu {
  width: 100%;
  font-size: 1.1em;
}

.rbt-close {
  position: absolute;
  z-index: 2;
  right: 50px;
  top: 7px;
  background-color: white !important;
}

.rbt-close-content {
  color: transparent;
}

.lupa {
  position: absolute;
  z-index: 2;
  right: 0%;
  background-color: white;
  height: 100%;
}
