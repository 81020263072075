.contRubrosList {
  min-width: auto;
  flex: auto;
}

.cuadrosRL {
  width: 100px;
  padding: 0%;
  margin: 0%;
  font-weight: 100;
  padding-top: 3px;
}

.cardRL {
  text-decoration: none;
  color: black;
}

.imgRL {
  width: 100%;
  height: 100px;
  object-fit: cover;
  border: black 3px solid;
  border-radius: 5px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.imgRL:hover {
  transform: translateY(-4px);
}

.tituloRL {
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 0.25em;
  line-height: normal;
}
